<template>
  <div ref="blockRef" class="lg:flex lg:items-center lg:min-h-screen">
    <div class="mx-auto px-4 w-full max-w-6xl">
      <div class="lg:flex lg:items-end lg:justify-between">
        <h2
          v-if="block.title"
          v-html="formatTitle(block.title)"
          :class="`grow text-[clamp(1.5rem,-0.6818rem+10.9091vw,3.75rem)] leading-none whitespace-nowrap max-w-[41.5rem] ${block.title_color.value}`"
          data-gsap-target="title"
        />

        <Buttons
          v-if="block.buttons?.length"
          :buttons="block.buttons"
          button-class="mt-8"
          data-gsap-target="buttons"
        />
      </div>

      <div
        v-if="block.accordion_items?.length"
        class="grid grid-cols-1 mt-12 md:grid-cols-2 md:items-center md:mt-20"
        data-gsap-target="accordion"
      >
        <div class="space-y-6 md:pr-12 lg:pr-28">
          <div
            v-for="(item, index) in block.accordion_items"
            :key="index"
            class="opacity-50"
            data-gsap-target="accordionItem"
          >
            <div class="relative pl-12 font-serif font-medium text-xl text-purple-dark lg:text-2xl">
              <div class="absolute top-0 left-0 font-serif italic">
                0{{ index + 1 }}
              </div>

              <button
                type="button"
                class="font-serif text-left"
                @click="itemsTimeline.play(`gsap-item-${index}`)"
              >
                {{ item.accordion_item_title }}
              </button>

              <div
                class="prose overflow-hidden h-0"
                data-gsap-target="accordionItemText"
              >
                <p class="pt-3">
                  {{ item.accordion_item_text }}
                </p>
              </div>
            </div>

            <div class="relative mt-6 h-px bg-purple-dark">
              <div
                class="absolute left-0 inset-y-0 bg-orange"
                data-gsap-target="accordionItemProgressBar"
              />
            </div>
          </div>
        </div>

        <div class="hidden relative mt-12 ml-auto w-full aspect-[25/27] rounded-2xl overflow-hidden md:block md:mt-0 md:max-w-md">
          <div
            v-for="(item, index) in block.accordion_items"
            :key="index"
            class="absolute inset-0 h-full w-full object-cover opacity-0"
            data-gsap-target="accordionImage"
            @mouseenter="itemsTimeline.pause()"
            @mouseleave="itemsTimeline.resume()"
          >
            <img
              v-if="item.accordion_item_image"
              :src="item.accordion_item_image.sm.url"
              :srcSet="`
                ${item.accordion_item_image.lg.url} ${item.accordion_item_image.lg.width}w,
                ${item.accordion_item_image.md.url} ${item.accordion_item_image.md.width}w,
                ${item.accordion_item_image.sm.url} ${item.accordion_item_image.sm.width}w
              `"
              sizes="
                (min-width: 928px) 448px,
                (min-width: 768px) 380px,
                95vw
              "
              :alt="item.accordion_item_image.sm.alt"
              :width="item.accordion_item_image.sm.width"
              :height="item.accordion_item_image.sm.height"
              class="h-full w-full object-cover"
              loading="lazy"
              draggable="false"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Accordion } from '~/statamic/types/block';

defineProps<{
  block: Accordion;
}>();

let ctx: gsap.Context | null;
let itemsTimeline: gsap.core.Timeline;
const blockRef = ref();

onMounted(() => {
  ctx = useGsap.context((self) => {
    if (typeof self.selector !== 'function') return;

    const titleLeft = self.selector('[data-gsap-target="title"]')[0];
    const titleRight = self.selector('[data-gsap-target="title"] > .text-right')[0];
    const buttons = self.selector('[data-gsap-target="buttons"]');
    const accordion = self.selector('[data-gsap-target="accordion"]');
    const items = self.selector('[data-gsap-target="accordionItem"]');
    const images = self.selector('[data-gsap-target="accordionImage"]');

    useScrollTrigger.create({
      pin: isDesktop(),
      start: `top ${isDesktop() ? 'top' : 'bottom'}`,
      end: 'bottom bottom-=25%',
      trigger: blockRef.value,
    });

    const timeline = useGsap.timeline({
      scrollTrigger: {
        start: 'top center',
        trigger: blockRef.value,
      },
    });

    if (titleLeft) {
      timeline.from(titleLeft, {
        x: -100,
        autoAlpha: 0,
      });
    }

    if (titleRight) {
      timeline.from(titleRight, {
        x: 100,
        autoAlpha: 0,
      }, '-=0.25');
    }

    if (buttons?.length) {
      timeline.from(buttons, {
        x: 100,
        autoAlpha: 0,
      });
    }

    if (accordion) {
      timeline.from(accordion, {
        autoAlpha: 0,
      }, '-=0.75');
    }

    itemsTimeline = useGsap.timeline({
      repeat: -1,
      repeatDelay: 1,
      scrollTrigger: {
        start: 'top center',
        trigger: blockRef.value,
      },
    });

    items.forEach((item: HTMLElement, i: number) => {
      const text = item.querySelector('[data-gsap-target="accordionItemText"]');
      const progressBar = item.querySelector('[data-gsap-target="accordionItemProgressBar"]');
      const previousItem = items[i - 1];

      if (previousItem) {
        itemsTimeline.set(items[i - 1], { opacity: 0.5 });
      }

      itemsTimeline
        .addLabel(`gsap-item-${i}`)
        .set(item, { opacity: 0.5 })
        .set(images[i], { opacity: 0 })
        .set(progressBar, { width: 0 })
        .to(text, { height: 'auto', opacity: 1, duration: 0.5 })
        .to(item, { opacity: 1 })
        .to(images[i], { opacity: 1 })
        .to(progressBar, { width: '100%', duration: 7 })
        .to(text, { height: 0, opacity: 0, duration: 0.5 })
        .to(progressBar, { width: '100%', duration: 0.5 }, '-=0.5');
    });
  }, blockRef.value);
});

onUnmounted(() => {
  ctx?.revert();
});
</script>
